/*
const ProjectsList = [
    {
        name: "Rit",
        screenshots: ["https://twitter.com/GuglioIsStupid/status/1441377877877878787/photo/1", "https://media.discordapp.net/attachments/783856131189112842/1174819455080071238/library_logo_transparent.png?ex=6568fae1&is=655685e1&hm=65f594ae00da21c5970031143e8072d9d2db6451a7782d7a1e46cbe8477d1998"],
        description: "A free and open-sourced Rhythm game made with Love2D.",
        url: "https://github.com/GuglioIsStupid/Rit"
    },
    {
        name: "GvG",
        screenshots: ["https://twitter.com/GuglioIsStupid/status/1441377877877878787/photo/1""],
        description: "ARPG game.",
        url: "https://github.com/GuglioIsStupid/Rit"
    }
]
*/

import React from 'react';

class ProjectItem extends React.Component {
    render() {
        return (
            // based off the amount of screenshots, the grid will change, disPLAY the images. NO RESIZE
            // is a rectangle (long height) and has a border

            <div className="flex flex-col items-center justify-start w-full h-20 p-4 text-left">
                <div className="flex flex-row items-center justify-start w-full h-20 p-4 text-left">
                    <div className="flex flex-col items-center justify-start w-full h-20 p-4 text-left">
                        <p className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
                            {this.props.name}
                        </p>
                        <p className="text-lg font-semibold text-gray-700 dark:text-white">
                            {this.props.description}
                        </p>
                    </div>
                </div>
            </div>

        );
    }
}

export default ProjectItem;