import React from 'react';
import HomeButton from '../components/HomeButton.js';

const Donate = () => {
    return (
        <div className="App bg-gray-100 dark:bg-gray-900">
            <HomeButton />

            <div className="App flex flex-col items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <p className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl mb-4">
                    Donate
                </p>

                <br/>

                <iframe
                    id='kofiframe'
                    src='https://ko-fi.com/guglioisstupid/?hidefeed=true&widget=true&embed=true&preview=true'
                    height='712'
                    width='25%'
                    style={{border: "none", padding: "4px", background: "#f9f9f9"}}
                    className="rounded-lg shadow-lg border-2 border-gray-200 dark:border-gray-700
                    dark:bg-gray-800 dark:text-white dark:shadow-none dark:ring-0 dark:ring-gray-900"
                />
            </div>
        </div>
        
    )
}

export default Donate;