import React from 'react';
import { Link } from "react-router-dom";

function CallLinkTo(url) {
    // use <Link> to link to a page
    <Link to={url} />
}

class PageButton extends React.Component {
    render() {
        return (
            // drop text when hovered, change mouse to a pointer, change the background color to the color of the social media and make a box shadow (makes it look like its popping out) and lift the button up
            <Link to={this.props.url}>
                <div className="flex items-center justify-start w-full h-20 p-4 text-left
                    bg-white border-2 border-gray-200 rounded-lg dark:bg-gray-800
                    dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900
                    hover:shadow-lg dark:hover:shadow-lg hover:translate-y-1 dark:hover:translate-y-1
                    transition duration-500 ease-in-out transform hover:scale-105 dark:hover:scale-105
                    cursor-pointer" 
                    onClick={CallLinkTo(this.props.url)}>

                    {/*Icon is text*/}
                    <div className="text-2xl font-bold text-gray-800 dark:text-white">
                        {this.props.icon}
                    </div>

                    <div className="ml-4 text-lg font-semibold text-gray-700 dark:text-white">
                        {this.props.name}
                    </div>
                </div>
            </Link>
        );
    }
}

export default PageButton;