import React from 'react';
import HomeButton from '../components/HomeButton.js';

const Contact = () => {
    return (
        <div className="App bg-gray-100 dark:bg-gray-900">
            <HomeButton />

            <div className="App flex flex-col items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <p className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
                    Contact
                </p>
            </div>
        </div>
    )
}

export default Contact;