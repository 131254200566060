import React from "react";
import SocialMediaItem from '../components/SocialMediaItem.js';
/* import ProjectItem from '../components/ProjectItem.js'; */
import PageButton from '../components/PageButton.js';

const SocialMediaList = [
    {
        name: "Github",
        username: "GuglioIsStupid",
        image: "https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png",
        url: "https://github.com/GuglioIsStupid",
        color: "black"
    },
    {
        name: "Twitter",
        username: "GuglioIsStupid",
        image: "https://seeklogo.com/images/T/twitter-icon-square-logo-108D17D373-seeklogo.com.png",
        url: "https://twitter.com/GuglioIsStupid",
        color: "blue"
    },
    {
        name: "Discord",
        username: "guglioisstupid",
        image: "https://seeklogo.com/images/D/discord-icon-new-2021-logo-09772BF096-seeklogo.com.png",
        url: "https://discord.gg/ehY5gMMPW8",
        color: "purple"
    }
]

const Pages = [
    {
        name: "Projects",
        url: "/projects",
        icon: "📂"
    },
    {
        name: "About",
        url: "/about",
        icon: "👤"
    },
    {
        name: "Contact",
        url: "/contact",
        icon: "📞"
    },
    {
        name: "Donate",
        url: "/donate",
        icon: "💰"
    }
]

// get my discord info from https://api.leaked.wiki/discorduser?id=705138718926700744
function GetDiscordInfo() {
    fetch('https://api.leaked.wiki/discorduser?id=705138718926700744').then(response => response.json()).then(data => {
        // change img id ballslol to the avatar url
        document.getElementById("ballslol").src = data.avatar;
    });
}

GetDiscordInfo();

const Home = () => {
    return (
        <div className="App flex flex-col items-center min-h-screen bg-gray-100 dark:bg-gray-900">
        {/*portfolio*/}
        <div className="flex flex-col items-center justify-center w-full text-center h-96">
           <img src={""} alt="GuglioIsStupid" id="ballslol" className="w-32 h-32 rounded-full shadow-lg border-2 border-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:shadow-none dark:ring-0 dark:ring-gray-900" />
            <p className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
                GuglioIsStupid
            </p>
            <p className="text-xl text-gray-500 dark:text-gray-300 md:text-2xl md:px-24">
                I'm a stupid person who likes to code.
            </p>
        </div>

        {/*Social Media title*/}
        <div className="flex items-center justify-center w-full h-24 border-b">
            <p className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
                Socials
            </p>
        </div>

        {/*4x{} grid of social media items (CENTERED)*/}
        <div className="grid w-full grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:px-24">
        {SocialMediaList.map((item) => (
            <SocialMediaItem 
                name={item.name} 
                username={item.username} 
                image={item.image} 
                url={item.url} 
                color={item.color} 
                usernameext1={item.usernameext1} 
                usernameext2={item.usernameext2}
            />
        ))}
        </div>

        {/*Projects title*/}

        {/*Other pages title*/}
        <div className="flex items-center justify-center w-full h-24 border-b">
            <p className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
                Other pages
            </p>
        </div>

        {/*4x{} grid of other pages*/}
        <div className="grid w-full grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:px-24">
        {Pages.map((item) => (
            <PageButton 
                name={item.name} 
                url={item.url} 
                icon={item.icon} 
            />
        ))}
        </div>

        {/*footer*/}
        <footer className="flex items-center justify-center w-full h-24 border-t">
            <p className="text-gray-500 dark:text-gray-300">
            Made with ❤️ by GuglioIsStupid with TailwindCSS and React.
            </p>
        </footer>
        
        </div>
    );
}

export default Home;
