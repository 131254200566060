import React from 'react';

class SocialMediaItem extends React.Component {
    render() {
        return (
            // when hovered, change the background color to the color of the social media and make a box shadow (makes it look like its popping out) and lift the button up
            <button className="flex items-center justify-start w-full h-20 p-4 text-left 
                bg-white border-2 border-gray-200 rounded-lg dark:bg-gray-800 
                dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900
                hover:shadow-lg dark:hover:shadow-lg hover:translate-y-1 dark:hover:translate-y-1
                transition duration-500 ease-in-out transform hover:scale-105 dark:hover:scale-105"
                onClick={() => window.open(this.props.url)}>

                <img src={this.props.image} alt={this.props.name} className="w-12 h-12 rounded-full" />

                <div className="ml-4 text-lg font-semibold text-gray-700 dark:text-white">
                    {this.props.name}
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-200">
                        {this.props.usernameext1}@{this.props.username}{this.props.usernameext2}
                    </p>
                </div>
            </button>
        );
    }
}

export default SocialMediaItem;
