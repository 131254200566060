import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// create a social media item
/*tailwindcss portfolio*/

// import components/SocialMediaItem.js

// pages
import Home from './pages/Home.js';
import Projects from './pages/Projects.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';
import Donate from './pages/Donate.js';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/donate" element={<Donate />} />
        </Routes>
    );
}

export default App;
