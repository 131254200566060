import React from 'react';
import { Link } from 'react-router-dom';
import 'material-icons/iconfont/material-icons.css';

const HomeButton = () => {
    return (
        <Link to="/">
            {/*Blue button*/}
            <button className="flex items-center justify-center w-12 h-12 p-3 text-white rounded-full bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                {/*left arrow*/}
                <i className="material-icons">arrow_back</i>
            </button>
        </Link>
    )
}

export default HomeButton;