import React from "react";

import ProjectItem from '../components/ProjectItem.js';
import HomeButton from '../components/HomeButton.js';

const ProjectsList = [
    {
        name: "Rit",
        screenshots: [
            "https://media.discordapp.net/attachments/783856131189112842/1174819455080071238/library_logo_transparent.png"
        ],
        description: "A free and open-sourced Rhythm game made with Love2D.",
        url: "https://github.com/GuglioIsStupid/Rit"
    }
]

const Projects = () => {
    return (
        <div className="App bg-gray-100 dark:bg-gray-900">
            <HomeButton />
            
            <div className="App flex flex-col items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                    <p className="text-2xl font-bold text-gray-800 dark:text-white md:text-3xl">
                        Projects
                    </p>
                        
                    {/*Projects grid*/}

                    <div className="grid w-full grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:px-24">
                        {ProjectsList.map((project) => (
                            <ProjectItem name={project.name} screenshots={project.screenshots} description={project.description} url={project.url} />
                        ))}
                    </div>
            </div>
        </div>
    )
}

export default Projects;